export const environment = {
  production: true,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  codigoSistema: '0001',
  timeToRefreshToken: 30000,
  channel: 'web',
  merchantId: '522591303',
  endPointJS: "https://static-content-qas.vnforapps.com/v2/js/checkout.js?qa=true",
  siteKey: '6LfHaL8ZAAAAAB93PcrhhCykIZ8FNtJhrRIqrxXr',
  privateKey: 'pk_test_3e69f2e8a7c482e8',

  apiKeyGoogle: 'AIzaSyBAsd6z0PbJVaeX9WZcrd32IUlY6STbeAo',

  social: {
    FACEBOOK_ID: '313635033369228',
    GOOGLE_ID: '742815218794-7gf5c9eslqbvalftjipq1vu3097qtd34.apps.googleusercontent.com'
  },

  jwt: {
    audience: 'DefaultAudience',
    issuer: 'DefaultIssuer'
  },

  /*WEB SERVICES*/
  api: {
    WS_WEB: {
      // url: 'http://35.190.132.15:9136/WS_WEB/api',
      url: 'https://api.enruta.pe:9136/WS_WEB/api',
      basicAuthorization: {
        username: 'VnITApp',
        password: '975318642'
      }
    },
    WS_IT: {
      // url: 'http://35.190.132.15:9136/WS_IT/api',
      url: 'https://api.enruta.pe:9136/WS_IT/api',
      basicAuthorization: {
        username: 'VnITApp',
        password: '975318642'
      }
    },
    WS_ECOMMERCE: {
      url: 'http://localhost:50073/api',
      basicAuthorization: {
        username: 'VnSDApp',
        password: '975318642'
      }
    },
    WS_TARIFARIO: {
      url: 'http://waetitaxi.azurewebsites.net/WS_TX/api/tarifa'
    },
    WS_VISANET: {
      url: 'http://localhost:60777/api',
      basicAuthorization: {
        username: 'VnVNApp',
        password: '975318642'
      }
    }
  },
  firebaseConfig: {
    apiKey: "AIzaSyC3KA1n5Cz5wHjZO96I2lYQZBWQj2NtYls",
    authDomain: "reyesnorteerp.firebaseapp.com",
    projectId: "reyesnorteerp",
    storageBucket: "reyesnorteerp.appspot.com",
    messagingSenderId: "1060562162620",
    appId: "1:1060562162620:web:85d34f3068af53be75d1b9"
  }
};
import { Component, OnInit, Inject, Renderer, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { DOCUMENT } from '@angular/platform-browser';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { AuthService } from './_core/services/auth.service';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'body[m-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;

    constructor( private renderer : Renderer, private router: Router, @Inject(DOCUMENT,) 
    private document: any, 
    private element : ElementRef, 
    public location: Location,
    private authService: AuthService,
    private metaTagService: Meta,
    private titleService: Title
    ) {}

    private intervalRefreshToken = null;

    ngOnInit() {
      this.intervalRefreshToken = setInterval(() => {
        if (this.authService.isLoggedIn()) {
          this.authService.refreshToken().subscribe(() => { });
        }
      }, environment.timeToRefreshToken)
    }

  ngOnDestroy(): void {
		if (this.intervalRefreshToken != null) {
			this.intervalRefreshToken.clearInterval();
		}
  }

  onActivate(event) {
    if (this.router.url != '/Beneficios') {
      window.scroll(0,0);
    }
  }
}
